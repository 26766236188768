require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#7aad36',
        accent: '#0d6efd',
        danger: '#de5900',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        men: '#70839f',
        woman: '#b795b5',
        second: '#21b58d',
        white: '#ffffff',
        disease: '#962610',
        license: '#2a4b1b',
        vacation: '#9107b9',
        others: '#326772',
      },
      dark: {
        primary: '#7aad36',
        accent: '#0d6efd',
        danger: '#962610',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        men: '#70839f',
        woman: '#b795b5',
        second: '#21b58d',
        white: '#ffffff',
        disease: '#280243',
        license: '#2a4b1b',
        vacation: '#9107b9',
        others: '#326772',
      },
    },
  },
}
