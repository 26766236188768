/* eslint-disable */
const moment = require("moment");
/*
datos = [...] // Datos a reportar (Array)
opciones = {
  archivoNombre: 'informe',
  titulo: {data: "", opciones: {}},
  columnas: [
        {
          key: "fecha",
          label: "Fecha",
          data: moment(data.fecha).format('DD/MM/YYYY HH:mm'),
          tipo: "fecha", // fecha, fechaHora, texto, numero,
          width: 80,
          sumatoria: true,
          zborder: { left: 1, right: 1, top: 1, bottom: 0 }
        },
        ...
      ],
  agrupado: {
    columna: 'cadeteID',
    columnaTitulo: 'cadete',

  },
  sumatorias: ["monto", "montoTotal"]

}
*/
function reporte(datos, opciones) {
  const mydata = datos;
  const myoptions = opciones;
  var detalleFila = function(report, data) {
    let filas = [];
    myoptions.columnas.forEach((el, i) => {
      let filaData = data[el.key];
      if (el.tipo == "fecha") {
        filaData = moment(data[el.key]).format("DD/MM/YYYY");
      } else if (el.tipo == "fechaHora") {
        filaData = moment(data[el.key]).format("DD/MM/YYYY HH:mm");
      } else if (el.tipo == "dinero") {
        filaData = parseFloat(data[el.key]).toLocaleString("es-AR", {
          style: "currency",
          currency: "ARS"
        });
      } else if (el.tipo == "numero") {
        filaData = data[el.key].toFixed(2);
      } else if (el.tipo == "booleano") {
        filaData = data[el.key] ? "SI" : "NO";
      }
      filas.push({
        data: filaData,
        width: el.width,
        align: el.align,
        zborder: el.zborder
      });
    });
    report.band(filas, { border: 1, width: 0, wrap: 1 });
    console.log(data);
    if ("detallesReserva" in data) {
      if (data.detallesReserva) {
        data.detallesReserva.forEach((el2, i2) => {
          let filasDR = [];
          if (i2 == 0) {
            filasDR.push({ data: data.reservaID, width: 115 });
          } else {
            filasDR.push({ data: "", width: 115 });
          }
          filasDR.push({
            data: el2.detalles,
            width: 235,
            align: "left",
            zborder: { left: 1, right: 1, top: 0, bottom: 0 }
          });
          filasDR.push({
            data: parseFloat(el2.monto).toLocaleString("es-AR", {
              style: "currency",
              currency: "ARS"
            }),
            width: 75,
            align: "right",
            zborder: { left: 1, right: 1, top: 0, bottom: 0 }
          });
          report.band(filasDR, { border: 0, width: 0, wrap: 1 });
        });
        report.band([{data:""}], { border: 0, width: 0, wrap: 1, collapse:true });
      }
    }
    if ("detallesCC" in data) {
      if (data.detallesCC) {
        data.detallesCC.forEach((el2, i2) => {
          let filasDR = [];
          if (i2 == 0){
            filasDR.push({ data: "Movimientos Asociados:", width: 450, zborder: { left: 0, right: 0, top: 0, bottom: 1 } } );
            report.band(filasDR, { border: 0, width: 0, wrap: 1 });
            filasDR = [];
          }
          filasDR.push({
            data: "ID CAJA: "+el2.turnoID,
            width: 75,
            align: "left",
            zborder: { left: 0, right: 0, top: 0, bottom: 0 }
          });
          filasDR.push({
            data: "RESERVA: "+el2.codigo,
            width: 90,
            align: "left",
            zborder: { left: 0, right: 0, top: 0, bottom: 0 }
          });
          filasDR.push({
            data: el2.cliente,
            width: 185,
            align: "left",
            zborder: { left: 0, right: 0, top: 0, bottom: 0 }
          });
          filasDR.push({
            data: parseFloat(el2.monto).toLocaleString("es-AR", {
              style: "currency",
              currency: "ARS"
            }),
            width: 75,
            align: "right",
            zborder: { left: 0, right: 0, top: 0, bottom: 0 }
          });
          report.band(filasDR, { border: 0, width: 0, wrap: 1 });
        });
        report.band([{data:""}], { border: 0, width: 0, wrap: 1, collapse:true });
      }
    }
  };
  var footerFuncion = function(report) {
    //Comentado por Juanjo
    // report.line(
    //   report.currentX(),
    //   report.maxY() - 18,
    //   report.maxX(),
    //   report.maxY() - 18
    // );
    report.pageNumber({
      text: "Pagina: {0} de {1}",
      footer: true,
      align: "right"
    });
    // Comentado por Juanjo
    // report.print("Fecha de Impresion: " + new Date().toLocaleDateString(), {
    //   y: report.maxY() - 14,
    //   align: "left"
    // });
  };
  var header = function(report, data, state) {
    if (myoptions.empresa) {
      report.print(
        myoptions.empresa + " - " + moment().format("DD/MM/YYYY HH:mm"),
        {
          fontSize: 8,
          align: "left"
        }
      );
    }
    if (myoptions.turnoID) {
      report.print(
        "ID CAJA: " + myoptions.turnoID,
        {
          fontSize: 8,
          align: "left"
        }
      );
    }
    if (myoptions.usuario) {
      report.print("Impreso por: " + myoptions.usuario, {
        fontSize: 8,
        align: "left"
      });
    }
    report.newline();
    report.newline();
    if (typeof myoptions.titulo === "object") {
      report.print(
        myoptions.titulo.data,
        myoptions.titulo.myoptions
          ? myoptions.titulo.myoptions
          : { fontBold: true, fontSize: 15, align: "center" }
      );
    } else {
      report.print(myoptions.titulo, {
        fontBold: true,
        fontSize: 15,
        align: "center"
      });
    }
    report.newline();
    report.newline();
    // Detail Header
    report.fontBold();
    let headers = [];
    myoptions.columnas.forEach((el, i) => {
      headers.push(el);
      headers[i].data = el.label;
    });
    report.band(headers);
    report.fontNormal();
    report.bandLine();
  };
  var namefooter = function(report, data, state) {
    report.bandLine();
    let sumatoriaF = [];
    myoptions.columnas.forEach(el => {
      let sum = [];
      sum.push(
        el.sumatoria && myoptions.sumatoria.includes(el.key)
          ? el.tipo == "dinero"
            ? parseFloat(report.totals[el.key]).toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS"
              })
            : report.totals[el.key]
          : ""
      );
      sum.push(el.width);
      sum.push(el.align ? el.align : 1);
      sumatoriaF.push(sum);
    });
    report.band(sumatoriaF, { addY: 1 });
    report.bandLine();
    report.newLine();
    // ARMO CAJA DE CUENTA CORRIENTE:
    const mydata = datos;
    console.log('mydata', datos)
    let b = 0;
    mydata.forEach(el => {
      if (el.montoCuentaCorriente){
        if (b==0){
          let filas = [];
          filas.push({
            data: "DETALLE DE MOVIMIENTOS DE CUENTA CORRIENTE",
            width: 275,
          });
          report.band(filas);
          b=1;
        }
        let filas = [];
        filas.push({
          data: 'ID CAJA: '+el.detallesCC.turnoID,
          width: 75,
        });
        filas.push({
          data: el.detallesCC.titulo,
          width: 125,
        });
        filas.push({
          data: parseFloat(el.montoCuentaCorriente).toLocaleString("es-AR", {
            style: "currency",
            currency: "ARS"
          }),
          width: 75,
        });
        report.band(filas, { border: 1, width: 0, wrap: 1 });
      }
    });
  };
  var nameheader = function(report, data) {
    report.print(data[myoptions.agrupado.columnaTitulo], {
      fontBold: true,
      underline: true
    });
  };
  var totalFormatter = function(data, callback) {
    // if (data.hours) { data.hours = ': ' + data.hours; }
    callback(null, data);
  };
  let opcionesReport = {
    paper: myoptions.tipoHoja ? myoptions.tipoHoja : "A4",
    landscape: myoptions.orientacion
      ? myoptions.orientacion == "horizontal"
        ? true
        : false
      : false,
    fontSize: myoptions.fontSize ? myoptions.fontSize : 12
  };
  var rpt = new window.fluentReports.Report("buffer", opcionesReport)
    .margins({ left: 20, top: 20, bottom: 20, right: 20 })
    .autoPrint(false) // Optional
    .pageHeader(header) // Optional
    .finalSummary(namefooter) // Optional
    .pageFooter(footerFuncion) // Optional
    .userdata({ hi: 1 }) // Optional
    .data(mydata); // REQUIRED
  console.log(myoptions);
  if (myoptions.sumatoria && myoptions.sumatoria.length) {
    myoptions.sumatoria.forEach(el => {
      rpt.sum(el);
    });
  }

  rpt
    .detail(detalleFila) // Optional
    .totalFormatter(totalFormatter) // Optional
    .fontSize(8); // Optional

  if (myoptions.agrupado) {
    if (myoptions.sumatoria) {
      if (myoptions.sumatoria.length == 1) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 2) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 3) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 4) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 5) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 6) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 7) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 8) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .sum(myoptions.sumatoria[7])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 9) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .sum(myoptions.sumatoria[7])
          .sum(myoptions.sumatoria[8])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 10) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .sum(myoptions.sumatoria[7])
          .sum(myoptions.sumatoria[8])
          .sum(myoptions.sumatoria[9])
          .header(nameheader)
          .footer(namefooter);
      }
    } else {
      rpt
        .groupBy(myoptions.agrupado.columna)
        .header(nameheader)
        .footer(namefooter);
    }
  }

  rpt.outputType("buffer").render((err, buffer) => {
    var blob, url;
    blob = new Blob([buffer], {
      type: "application/pdf"
    });
    url = window.URL.createObjectURL(blob);
    downloadURL(url, myoptions.archivoNombre + ".pdf", myoptions);
    setTimeout(function() {
      return window.URL.revokeObjectURL(url);
    }, 1000);
  });
}
function downloadURL(data, fileName, myoptions) {
  var a;
  a = document.createElement("a");
  a.href = data;
  a.target = "_blank";
  if (myoptions.descargar) {
    a.download = fileName;
  }
  document.body.appendChild(a);
  a.style = "display: none";
  a.click();
  a.remove();
}

export default {
  reporte
};
