<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>

          <theme-switcher></theme-switcher>
          <v-btn
            v-if="rolUserLogged == 'Administrador' || rolUserLogged == 'Recepcionista' || rolUserLogged == 'Gerente' || rolUserLogged == 'Encargado'"
            icon
            small
            class="ms-3"
            @click="openCheckin"
          >
            <v-icon>
              {{ icons.mdiAccountClockOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="rolUserLogged == 'Administrador' || rolUserLogged == 'Gerente' || rolUserLogged == 'Encargado'"
            icon
            small
            class="ms-3"
            @click="openCalendar()"
          >
            <v-icon>
              {{ icons.mdiTimerCogOutline }}
            </v-icon>
          </v-btn>

          <!-- <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn> -->

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <v-spacer></v-spacer> <span>
            &copy; 2022 <a
              href="#"
              class="text-decoration-none"
            >Done! Software</a></span>
        </div>
      </div>
    </v-footer>
    <check-in
      ref="checkinModal"
      @result="resultDialog"
    ></check-in>

    <v-snackbar
      v-model="Showsnackbar"
      top
      :color="colorSnack"
    >
      {{ mensajeSnack }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="Showsnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiMagnify, mdiBellOutline, mdiGithub, mdiAccountClockOutline, mdiTimerCogOutline,
} from '@mdi/js'

import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import CheckIn from '../views/checkinout/CheckIn.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    CheckIn,

  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      mensajeSnack: '',
      Showsnackbar: false,
      colorSnack: 'success',
      rolUserLogged: null,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiAccountClockOutline,
        mdiTimerCogOutline,
      },

    }
  },
  mounted() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },
  methods: {
    openCheckin() {
      this.$refs.checkinModal.open()
    },
    openCalendar() {
      this.$router.push({ name: 'calendar' })
    },
    resultDialog(re) {
      this.colorSnack = re.color
      this.mensajeSnack = re.msg
      this.Showsnackbar = true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
