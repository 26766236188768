<template>
  <v-row justify="center">
    <v-dialog
      v-model="active"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="align-center ">
          <!-- title -->
          <v-card-text class="align-center">
            <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
              Fichada de Empleados
            </p>
            <p class="mb-2 align-center text-center">
              Ingrese el DNI del Empleado
            </p>
          </v-card-text>
        </v-card-title>
        <!-- login form -->
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      v-model="dni"
                      autofocus
                      id="dni"
                      outlined
                      type="number"
                      :rules="[Validaciones.requerido]"
                      label="DNI"
                      placeholder="Ingrese su Usuario"
                      class="mb-3"
                      hide-details=""
                      v-on:keyup.enter="searchEmployee"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <!-- <v-btn
                      color="primary"
                      :loading="loadingButton"
                      @click="searchEmployee"
                    >
                      <v-icon>
                        {{ icons.mdiAccountSearchOutline }}
                      </v-icon> Buscar
                    </v-btn> -->
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row class="mt-2">
                  <v-col md="6">
                    <v-text-field
                      v-model="name"
                      outlined
                      dense
                      readonly
                      :rules="[Validaciones.requerido]"
                      label="Nombre"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="surName"
                      outlined
                      dense
                      readonly
                      :rules="[Validaciones.requerido]"
                      label="Apellido"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="space-between mb-3">
          <v-spacer></v-spacer>
          <!-- <v-btn
            text
            color="primary"
            :loading="loadingButton"
            @click="insertCheckin()"
          >
            Registrar Fichada
          </v-btn> -->
          <v-btn
            text
            color="secondary"
            @click="active = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiAccountSearchOutline } from '@mdi/js'
import axios from 'axios'
import { fa } from 'vuetify/lib/locale'

export default {
  icons: {
    mdiAccountSearchOutline,
    mdiEyeOutline,
    mdiEyeOffOutline,
  },
  data: () => ({
    active: false,
    dni: null,
    name: null,
    surName: null,
    employeeID: null,
    loadingButton: false,
    valid: false,
    response: null,
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiAccountSearchOutline,
    },
    Validaciones: {
      requerido: v => !!v || 'Campo Requerido',
    },
  }),

  methods: {
    open() {
    
      this.active = true
 
      
    },
    async insertCheckin() {
      this.loadingButton = true
      this.url = `${this.$store.getters.urlBase}/api/Employee/checkin`

      const params = {}
      params.employeeID = parseInt(this.employeeID)
      params.loggedEmployeeID = parseInt(this.$store.getters.usuario.user_personid)

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
      }
      await axios
        .post(this.url, params, config)
        .then(
          response => {
            this.$emit('result', { color: 'success', msg: response.data })
            this.$store.dispatch('showSnack', {
              text: 'Fichada registrada',
              color: 'success',
              timeout: 2500,
            })
            this.$refs.form.reset()
            this.active = false
            this.loadingButton = false
          },
        )
        .catch(e => {
          if (e.response.data) {
            this.mensajeSnack = `Error: ${e.response.data}`
          } else if (e.message) {
            this.mensajeSnack = `Error: ${e.message}`
          } else {
            this.mensajeSnack = 'Error de Conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,
          })
          this.loadingButton = false
        })
    },
    async searchEmployee() {
      this.loadingButton = true
      this.url = `${this.$store.getters.urlBase}/api/Employee/employeeByDNI`
      const { dni } = this
      await axios
        .get(`${this.url}?dni=${dni}`)
        .then(
          response => {
            this.name = response.data.name
            this.surName = response.data.surname
            this.employeeID = response.data.personID
            this.response = response
            this.loadingButton = false
            this.insertCheckin()
          },
        )
        .catch(error => {
          if (error.response) {
            this.$emit('result', { color: 'error', msg: `Error: ${error.response.data}` })
          } else {
            this.$emit('result', { color: 'error', msg: 'Error de Conexion' })
          }
          this.loadingButton = false
        })

      
    },
  },
}
</script>

  <style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
  </style>
