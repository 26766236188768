<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $store.getters.usuario.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ $store.getters.usuario.user_rol }} </small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Email -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Chat -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content> -->

        <!-- <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action> -->
      </v-list-item>

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Settings -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Pricing -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCurrencyUsd }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- FAQ -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider class="my-2"></v-divider>
      <!-- this.$store.getters.usuario.user_name === 'asosa' -->
      <v-list-item
        v-if="this.$store.getters.usuario.user_rol === 'Gerente' || this.$store.getters.usuario.user_rol === 'Encargado'"
        link
        @click="openDialogCashRegister()"
      >
        <v-list-item-icon class="me-2">
          <v-icon
            v-if="(this.$store.getters.caja.isOpen==false)"
            size="22"
          >
            {{ icons.mdiLockOpenVariant }}
          </v-icon>
          <v-icon
            v-if="(this.$store.getters.caja.isOpen==true)"
            size="22"
          >
            {{ icons.mdiLock }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="(this.$store.getters.caja.isOpen==false)">
            Abrir Caja
          </v-list-item-title>
          <v-list-item-title v-else>
            Cerrar Caja
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        link
        @click="cerrarSesion"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiLock,
  mdiLockOpenVariant,
} from '@mdi/js'

export default {

  setup() {
    return {
      caja: null,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiLock,
        mdiLockOpenVariant,
      },

    }
  },
  mounted() {
    // this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'SHOW_CASH_REGISTER') {
    //     this.caja = this.$store.getters.caja
    //     if (this.caja.isOpen) {
    //       this.isOpen = false
    //     } else {
    //       this.isOpen = true
    //     }
    //   }
    // })
  },

  methods: {

    openDialogCashRegister() {
      this.$store.dispatch('showDialogCash', {
        openModalCash: true,
      })
    },
    cerrarSesion() {
      this.$store.dispatch('agregarUsuarioAction', {})
      this.$store.dispatch('agregarCajaAction', {})
      this.$router.push('/')
      this.$store.dispatch('showSnack', {
        text: 'Sesion cerrada',
        color: 'error',
        timeout: 2500,
      })
    },

  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
