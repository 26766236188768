import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // urlBase: 'https://localhost:5001',

   urlBase: 'https://api.sistema-efectivoya.com.ar',

    //  urlBase: 'https://apidos.sistema-efectivoya.com.ar',
    usuarioActual: localStorage.usuarioActual,
    cashRegister: localStorage.cashRegister,
    text: '',
    color: '',
    timeout: '',
    openModalCash: false,
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text
      state.color = payload.color
      state.timeout = payload.timeout
    },
    agregarUsuario(state, usuarioActual) {
      // localStorage.setItem("usuarioActual", usuarioActual);
      localStorage.usuarioActual = usuarioActual
      state.usuarioActual = usuarioActual
    },
    agregarCaja(state, cashRegister) {
      // localStorage.setItem("usuarioActual", usuarioActual);
      localStorage.cashRegister = cashRegister
      state.cashRegister = cashRegister
    },
    SHOW_CASH_REGISTER(state, payload) {
      state.openModalCash = payload.openModalCash
    },
  },
  actions: {
    showSnack(context, payload) {
      context.commit('SHOW_MESSAGE', payload)
    },
    showDialogCash(context, payload) {
      context.commit('SHOW_CASH_REGISTER', payload)
    },
    agregarUsuarioAction(context, usuarioActual) {
      context.commit('agregarUsuario', JSON.stringify(usuarioActual))
    },
    agregarCajaAction(context, cashRegister) {
      context.commit('agregarCaja', JSON.stringify(cashRegister))
    },
  },
  getters: {
    snack(state) {
      return {
        text: state.text,
        color: state.color,
        timeout: state.temeout,
      }
    },
    usuario(state) {
      return state.usuarioActual ? JSON.parse(state.usuarioActual) : {}
    },
    caja(state) {
      return state.cashRegister ? JSON.parse(state.cashRegister) : {}
    },
    urlBase(state) {
      return state.urlBase
    },
    usuarioToken() {
      const token = {
        token: sessionStorage.getItem('Token'),
        expiration: sessionStorage.getItem('Expiration'),
      }

      return token
    },
  },
  modules: {},
})
