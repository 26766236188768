<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <v-card-title>
        <span
          v-if="this.$store.getters.caja.isOpen"
          class="text-h5"
        >Cerrar Caja</span>
        <span
          v-else
          class="text-h5"
        >Abrir Caja</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="amount"
                  label="Monto $"
                  type="number"
                  dense
                  hide-details="true"
                  outlined
                ></v-text-field>
              </v-col>
              <v-row>
                <v-col cols="12">
                </v-col>
              </v-row>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-row>
              <v-btn
                color="blue darken-1"
                text
                depressed
                @click="closeDialog()"
              >
                Cancelar
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                :loading="isSaving"
                depressed
                @click="OpenCloseCash()"
              >
                Confirmar
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'


export default {
  data() {
    return {
      stateCash: false,
      dialog: false,
      amount: 0,
      isSaving: false,
      title: 'Apertura o Cierre de CAJA',
      total: 0,
      caja: null,
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SHOW_CASH_REGISTER') {
        this.caja = this.$store.getters.caja
        this.dialog = true
      }
    })
  },

  methods: {

    closeDialog() {
      this.dialog = false
    },
    async OpenCloseCash() {
 
      this.url = `${this.$store.getters.urlBase}/api/CashRegister/OpenClose`

      const params = {
        // eslint-disable-next-line radix
        loggedPersonID: parseInt(this.$store.getters.usuario.user_personid),
        // eslint-disable-next-line radix
        open: !this.$store.getters.caja.isOpen,
        amount: parseFloat(this.amount),

      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params,
      }
      await axios
        .post(this.url, params, config)
        .then(
          // eslint-disable-next-line no-return-assign
          response => {
            this.respuesta = response
            if (this.respuesta.status === 200) {
              this.$store.dispatch('showSnack', {
                text: 'Operación Exitosa',
                color: 'success',
                timeout: 2500,
              })
              const cashRegister = {
                // cash_register: this.respuesta.data.cashregister,
                id: this.$store.getters.caja.id,
                date: this.moment(),
                amount: parseFloat(this.amount),
                isOpen: !this.$store.getters.caja.isOpen,

              }
              this.$store.dispatch('agregarCajaAction', cashRegister)
           
           //  this.$router.push({ name: 'dashboard' })
              this.closeDialog()
            }
          },
        )
        .catch(e => {
          if (e.response) {
            this.mensajeSnack = e.response.data
          } else {
            this.mensajeSnack = 'Error de conexion!!'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })
        })
    },
  },
}
</script>
