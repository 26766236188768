<template>
  <component :is="resolveLayout">
    <snack-bar />
    <cash-dialog></cash-dialog>
    <router-view v-if="SesionActiva"></router-view>
  </component>
</template>
<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import SnackBar from './components/base/SnackGlobal.vue'
import CashDialog from './components/base/CashDialog.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    SnackBar,
    CashDialog,
  },
  data: () => ({
  }),
  computed: {
    resolveLayout() {
  
      if (!(this.$store.getters.usuario === null || Object.entries(this.$store.getters.usuario).length === 0)) {
    

        return 'layout-content'
      }

      // this.$router.push('/login')
   

      return 'layout-blank'
      if (this.$router.layout === 'blank') return 'layout-blank'
    },
    SesionActiva() {
      if (!(this.$store.getters.usuario === null || Object.entries(this.$store.getters.usuario).length === 0)) {
        if (this.$store.getters.usuario.token.expiration
         && this.moment(this.$store.getters.usuario.token.expiration).diff(this.moment().format(), 'minute') <= 0) {
          return false
        }

        return true
      }

      return false
    },
  },

}
</script>
