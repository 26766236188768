import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import moment from 'moment'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import reportes from './utils/reportes/index'

Vue.config.productionTip = false

moment.locale('es')
Vue.prototype.moment = moment
Vue.prototype.$reportes = reportes
Vue.version = "1.0.5"
new Vue({
  router,
  moment,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
