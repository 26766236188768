/* eslint-disable */
const moment = require("moment");
/*
datos = [...] // Datos a reportar (Array)
opciones = {
  archivoNombre: 'informe',
  titulo: {data: "", opciones: {}},
  columnas: [
        {
          key: "fecha",
          label: "Fecha",
          data: moment(data.fecha).format('DD/MM/YYYY HH:mm'),
          tipo: "fecha", // fecha, fechaHora, texto, numero,
          width: 80,
          sumatoria: true,
          zborder: { left: 1, right: 1, top: 1, bottom: 0 }
        },
        ...
      ],
  agrupado: {
    columna: 'cadeteID',
    columnaTitulo: 'cadete',

  },
  sumatorias: ["monto", "montoTotal"]

}
*/
function reporte(datos, opciones) {
  const mydata = datos;
  const myoptions = opciones;
  var detalleFila = function(report, data) {
    let filas = [];
    myoptions.columnas.forEach((el, i) => {
      let filaData = data[el.key];
      if (el.tipo == "fecha") {
        filaData = moment(data[el.key]).format("DD/MM/YYYY");
      } else if (el.tipo == "fechaHora") {
        filaData = moment(data[el.key]).format("DD/MM/YYYY HH:mm");
      } else if (el.tipo == "dinero") {
        filaData = parseFloat(data[el.key]).toLocaleString("es-AR", {
          style: "currency",
          currency: "ARS"
        });
      } else if (el.tipo == "numero") {
        filaData = data[el.key].toFixed(2);
      } else if (el.tipo == "booleano") {
        filaData = data[el.key] ? "SI" : "NO";
      }
      filas.push({
        data: filaData,
        width: el.width,
        align: el.align,
        zborder: el.zborder
      });
    });
    report.band(filas, { border: 1, width: 0, wrap: 1 });
    console.log(data);
    if (myoptions.detallado == 1) {
      report.band([], { border: 1, width: 0, wrap: 1 });
      if ("detallesConsumo" in data) {
        if (data.detallesConsumo) {
          let totalConsumo = 0;
          data.detallesConsumo.forEach((el2, i2) => {
            let filasDR = [];
            if (i2 == 0) {
              filasDR.push({ data: "CONSUMOS: ", fontBold: true, width: 60 });
            } else {
              filasDR.push({ data: "", width: 60 });
            }
            filasDR.push({
              data: el2.tipoPagoPendiente,
              width: 100,
              align: "left",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            filasDR.push({
              data: el2.descripcion,
              width: 260,
              align: "left",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            filasDR.push({
              data: moment(el2.fechaHora).format("DD/MM/YYYY HH:mm"),
              width: 70,
              align: "left",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            filasDR.push({
              data: parseFloat(el2.monto).toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS"
              }),
              width: 65,
              align: "right",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            totalConsumo += parseFloat(el2.monto);
            report.band(filasDR, { border: 1, width: 0, wrap: 1 });
          });
          let filasFinal = [];
          filasFinal.push({ data: "", width: 60 });
          filasFinal.push({
            data: "TOTAL: ",
            align: "right",
            width: 430,
            fontBold: true
          });
          filasFinal.push({
            data: parseFloat(totalConsumo).toLocaleString("es-AR", {
              style: "currency",
              currency: "ARS"
            }),
            width: 65,
            align: "right",
            zborder: { left: 1, right: 1, top: 0, bottom: 0 },
            fontBold: true
          });
          report.band(filasFinal, { border: 1, width: 0, wrap: 1 });
        }
      }
      if ("pagos" in data) {
        if (data.pagos) {
          let totalConsumo = 0;
          data.pagos.forEach((el2, i2) => {
            let filasDR = [];
            if (i2 == 0) {
              filasDR.push({ data: "PAGOS: ", fontBold: true, width: 60 });
            } else {
              filasDR.push({ data: "", width: 60 });
            }
            filasDR.push({
              data: el2.formaPago,
              width: 360,
              align: "left",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            filasDR.push({
              data: moment(el2.fechaHora).format("DD/MM/YYYY HH:mm"),
              width: 70,
              align: "left",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            filasDR.push({
              data: parseFloat(el2.monto).toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS"
              }),
              width: 65,
              align: "right",
              zborder: { left: 1, right: 1, top: 0, bottom: 0 }
            });
            totalConsumo += parseFloat(el2.monto);
            report.band(filasDR, { border: 1, width: 0, wrap: 1 });
          });
          let filasFinal = [];
          filasFinal.push({ data: "", width: 60 });
          filasFinal.push({
            data: "TOTAL: ",
            align: "right",
            width: 430,
            fontBold: true
          });
          filasFinal.push({
            data: parseFloat(totalConsumo).toLocaleString("es-AR", {
              style: "currency",
              currency: "ARS"
            }),
            width: 65,
            align: "right",
            zborder: { left: 1, right: 1, top: 0, bottom: 0 },
            fontBold: true
          });
          report.band(filasFinal, { border: 1, width: 0, wrap: 1 });
        }
      }
      report.band([], { border: 1, width: 0, wrap: 1 });
      report.band([], { border: 1, width: 0, wrap: 1 });
      report.band([], { border: 1, width: 0, wrap: 1 });
      report.band([], { border: 1, width: 0, wrap: 1 });
    }
  };
  var footerFuncion = function(report) {
    report.line(
      report.currentX(),
      report.maxY() - 10,
      report.maxX(),
      report.maxY() - 10
    );
    report.pageNumber({
      text: "Pagina: {0} de {1}",
      footer: true,
      align: "right"
    });
    report.print("Fecha de Impresion: " + new Date().toLocaleDateString(), {
      y: report.maxY() - 6,
      align: "left"
    });
  };
  var header = function(report, data, state) {
    if (myoptions.empresa) {
      report.print(
        myoptions.empresa + " - " + moment().format("DD/MM/YYYY HH:mm"),
        {
          fontSize: 8,
          align: "left"
        }
      );
    }
    if (myoptions.usuario) {
      report.print("Impreso por: " + myoptions.usuario, {
        fontSize: 8,
        align: "left"
      });
    }
    report.newline();
    report.newline();
    if (typeof myoptions.titulo === "object") {
      report.print(
        myoptions.titulo.data,
        myoptions.titulo.myoptions
          ? myoptions.titulo.myoptions
          : { fontBold: true, fontSize: 15, align: "center" }
      );
    } else {
      report.print(myoptions.titulo, {
        fontBold: true,
        fontSize: 15,
        align: "center"
      });
    }
    report.newline();
    report.newline();
    // Detail Header
    report.fontBold();
    let headers = [];
    myoptions.columnas.forEach((el, i) => {
      headers.push(el);
      headers[i].data = el.label;
    });
    report.band(headers);
    report.fontNormal();
    report.bandLine();
  };
  var namefooter = function(report, data, state) {
    report.bandLine();
    let sumatoriaF = [];
    myoptions.columnas.forEach(el => {
      let sum = [];
      sum.push(
        el.sumatoria && myoptions.sumatoria.includes(el.key)
          ? el.tipo == "dinero"
            ? parseFloat(report.totals[el.key]).toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS"
              })
            : report.totals[el.key]
          : ""
      );
      sum.push(el.width);
      sum.push(el.align ? el.align : 1);
      sumatoriaF.push(sum);
    });
    report.band(sumatoriaF, { addY: 1 });
    report.bandLine();
    report.newLine();

    // CUADRO PAGOS Y CONSUMOS:
    const mydata = datos;
    console.log('mydata', datos)
    let b = 0;
    console.log('A IMPRIMIR EN CUADRO')
    console.log(mydata)
    let filas = [];
          filas.push({
            data: "DETALLE DE PAGOS Y CONSUMOS",
            width: 150,
          });
          report.band(filas);
          b=1;
          let consumos = 0;
          let pagos = 0;
    mydata.forEach((el, i2) => {
      if (el.detallesConsumo){
        el.detallesConsumo.forEach(item => {
          consumos += parseFloat(item.monto)
        });
        if (el.pagos){
          el.pagos.forEach(item => {
            pagos+= parseFloat(item.monto)
          });
        }
      }
    });
    // let filas = [];
  filas = []
  filas.push({ data: "CONSUMOS: ", fontBold: true, width: 80 });
  filas.push({
    data: parseFloat(consumos).toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS"
    }),
    width: 75,
    align: "right",
  });
  report.band(filas, { border: 1, width: 0, wrap: 1 });
  filas = []
  filas.push({ data: "PAGOS: ", fontBold: true, width: 80 });
  filas.push({
    data: parseFloat(pagos).toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS"
    }),
    width: 75,
    align: "right",
  });
  report.band(filas, { border: 1, width: 0, wrap: 1 });
  filas = []
    // mydata.forEach((el, i2) => {
    //   let filas = [];
    //   if (el.pagos){
    //     el.pagos.forEach(item => {
    //       // let filas = [];
    //       if (i2 == 0) {
    //         filas.push({ data: "PAGOS: ", fontBold: true, width: 60 });
    //       } else {
    //         filas.push({ data: "", width: 60 });
    //       }
    //     filas.push({
    //       data: 'Tipo Pago: '+item.formaPago,
    //       width: 100,
    //     });
    //     if (item.descripcion) {
    //       filas.push({
    //         data: item.descripcion,
    //         width: 115,
    //       });
    //     } else {
    //       filas.push({
    //         data: "Sin descripcion",
    //         width: 115,
    //       });
    //      }
    //     filas.push({
    //       data: parseFloat(item.monto).toLocaleString("es-AR", {
    //         style: "currency",
    //         currency: "ARS"
    //       }),
    //       width: 75,
    //       align: "right",
    //     });
    //     report.band(filas, { border: 1, width: 0, wrap: 1 });
    //     });
    //   }
    // });
  };
  var nameheader = function(report, data) {
    report.print(data[myoptions.agrupado.columnaTitulo], {
      fontBold: true,
      underline: true
    });
  };
  var totalFormatter = function(data, callback) {
    // if (data.hours) { data.hours = ': ' + data.hours; }
    callback(null, data);
  };
  let opcionesReport = {
    paper: myoptions.tipoHoja ? myoptions.tipoHoja : "A4",
    landscape: myoptions.orientacion
      ? myoptions.orientacion == "horizontal"
        ? true
        : false
      : false,
    fontSize: myoptions.fontSize ? myoptions.fontSize : 12
  };
  var rpt = new window.fluentReports.Report("buffer", opcionesReport)
    .margins({ left: 20, top: 20, bottom: 20, right: 20 })
    .autoPrint(false) // Optional
    .pageHeader(header) // Optional
    .finalSummary(namefooter) // Optional
    .pageFooter(footerFuncion) // Optional
    .userdata({ hi: 1 }) // Optional
    .data(mydata); // REQUIRED
  console.log(myoptions);
  if (myoptions.sumatoria && myoptions.sumatoria.length) {
    console.log(myoptions.sumatoria);
    myoptions.sumatoria.forEach(el => {
      rpt.sum(el);
    });
  }

  rpt
    .detail(detalleFila) // Optional
    .totalFormatter(totalFormatter) // Optional
    .fontSize(8); // Optional

  if (myoptions.agrupado) {
    if (myoptions.sumatoria) {
      if (myoptions.sumatoria.length == 1) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 2) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 3) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 4) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 5) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 6) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 7) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 8) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .sum(myoptions.sumatoria[7])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 9) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .sum(myoptions.sumatoria[7])
          .sum(myoptions.sumatoria[8])
          .header(nameheader)
          .footer(namefooter);
      } else if (myoptions.sumatoria.length == 10) {
        rpt
          .groupBy(myoptions.agrupado.columna)
          .sum(myoptions.sumatoria[0])
          .sum(myoptions.sumatoria[1])
          .sum(myoptions.sumatoria[2])
          .sum(myoptions.sumatoria[3])
          .sum(myoptions.sumatoria[4])
          .sum(myoptions.sumatoria[5])
          .sum(myoptions.sumatoria[6])
          .sum(myoptions.sumatoria[7])
          .sum(myoptions.sumatoria[8])
          .sum(myoptions.sumatoria[9])
          .header(nameheader)
          .footer(namefooter);
      }
    } else {
      rpt
        .groupBy(myoptions.agrupado.columna)
        .header(nameheader)
        .footer(namefooter);
    }
  }

  rpt.outputType("buffer").render((err, buffer) => {
    var blob, url;
    blob = new Blob([buffer], {
      type: "application/pdf"
    });
    url = window.URL.createObjectURL(blob);
    downloadURL(url, myoptions.archivoNombre + ".pdf", myoptions);
    setTimeout(function() {
      return window.URL.revokeObjectURL(url);
    }, 1000);
  });
}
function downloadURL(data, fileName, myoptions) {
  var a;
  a = document.createElement("a");
  a.href = data;
  a.target = "_blank";
  if (myoptions.descargar) {
    a.download = fileName;
  }
  document.body.appendChild(a);
  a.style = "display: none";
  a.click();
  a.remove();
}

export default {
  reporte
};
