<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/efectivo-ya-png.png')"
          max-height="100px"
          max-width="180px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <div
        v-for="item in menu"
        :key="item.title"
      >
        <nav-menu-group
          v-if="(item.childs && item.rol.includes(rolUserLogged)) || item.account.includes(nameUserLogged)"
          :title="item.title"
          :icon="item.icon"
        >
          <span
            v-for="child in item.childs"
            :key="child.title"
          >
            <nav-menu-link
              v-if="child.rol.includes(rolUserLogged) || child.account.includes(nameUserLogged)"
              :title="child.title"
              :icon="child.icon"
              :to="{ name:child.to }"
            ></nav-menu-link>
          </span>
        </nav-menu-group>
        <nav-menu-link
          v-else-if="item.rol.includes(rolUserLogged) || item.account.includes(nameUserLogged)"
          :title="item.title"
          :icon="item.icon"
          :to="{ name: item.to }"
        ></nav-menu-link>
      </div>
      <!-- <nav-menu-link
        title="Menú Principal"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="ABM Usuarios"
        :to="{ name: 'user' }"
        :icon="icons.mdiAccountKey"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Empleados"
        :to="{ name: 'employee' }"
        :icon="icons.mdiEmoticon"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Caja"
        :to="{ name: 'reportcash'}"
        :icon="icons.mdiCashMultiple"
      ></nav-menu-link> -->
      <!-- <nav-menu-group
        title="Clientes"
        :icon="icons.mdiAccountDetails"
      >
        <nav-menu-link
          title="Listado"
          :to="{ name: 'abmclient' }"
          :icon="icons.mdiViewList"
        ></nav-menu-link> -->
      <!-- <nav-menu-link
          title="Créditos"
          :to="{ name: 'credit' }"
          :icon="icons.mdiAlphaCCircle"
        ></nav-menu-link>
        <nav-menu-link
          title="Cobranzas"
          :to="{ name: 'payments' }"
          :icon="icons.mdiCurrencyUsd"
        ></nav-menu-link>
        <nav-menu-link
          title="Reportes"
          :to="{ name: 'report' }"
          :icon="icons.mdiPrinter"
        ></nav-menu-link> -->
      <!-- </nav-menu-group> -->
      <!-- <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    > -->
    <!-- <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img> -->
    <!-- </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountDetails,
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCashMultiple,
  mdiAlphaCCircle,
  mdiCurrencyUsd,
  mdiPrinter,
  mdiEmoticon,
  mdiAccountKey,
  mdiViewList,
  mdiAccountClockOutline,
  mdiCashRegister,
  mdiGestureTapButton,
  mdiTimerSand,
  mdiCheckCircle,
  mdiHandCoin,
  mdiCash,
  mdiClipboardList,
  mdiFinance,
  mdiCloseCircleMultipleOutline,
  mdiCheckboxMultipleMarkedCircleOutline,
  mdiClipboardTextClockOutline,
  mdiCalendarMonth,
  mdiMapMarkerPath,
  mdiAccountStar,
  mdiTextBoxCheck,
  mdiFileSign,
  mdiImageMarker,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    icons: {
      mdiHomeOutline,
      mdiAlphaTBoxOutline,
      mdiEyeOutline,
      mdiCreditCardOutline,
      mdiTable,
      mdiFileOutline,
      mdiFormSelect,
      mdiAccountCogOutline,
      mdiAccountDetails,
      mdiCashMultiple,
      mdiCash,
      mdiAlphaCCircle,
      mdiCurrencyUsd,
      mdiPrinter,
      mdiEmoticon,
      mdiAccountKey,
      mdiViewList,
      mdiAccountClockOutline,
      mdiCashRegister,
      mdiGestureTapButton,
      mdiTimerSand,
      mdiCheckCircle,
      mdiHandCoin,
      mdiClipboardList,
      mdiFinance,
      mdiCloseCircleMultipleOutline,
      mdiCheckboxMultipleMarkedCircleOutline,
      mdiClipboardTextClockOutline,
      mdiCalendarMonth,
      mdiMapMarkerPath,
      mdiAccountStar,
      mdiTextBoxCheck,
      mdiFileSign,
      mdiImageMarker, 
    },
    menu: [
      {
        title: 'Menu Principal',
        to: 'dashboard',
        icon: mdiHomeOutline,
        rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
        account: [],
      },
      {
        title: 'Caja',
        to: 'cash',
        icon: mdiCashRegister,
        rol: ['Administrador', 'Encargado', 'Gerente'],
        account: [],
      },
      {
        title: 'Empleados',
        to: '',
        icon: mdiEmoticon,
        rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
        account: [],
        childs: [
          {
            title: 'Listado',
            to: 'employee',
            icon: mdiClipboardList,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Rendiciones',
            to: 'collectionsToBill',
            icon: mdiCash,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Fichada',
            to: 'checkinout',
            icon: mdiAccountClockOutline,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },
          {
            title: 'Liquidación',
            to: 'salary',
            icon: mdiAccountStar,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          }],
      },
      {
        title: 'Creditos',
        to: '',
        icon: mdiAlphaCCircle,
        rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
        account: [],
        childs: [
          {
            title: 'Gestión',
            to: 'credit',
            icon: mdiGestureTapButton,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Pendientes',
            to: 'creditexpect',
            icon: mdiTimerSand,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },
          {
            title: 'Verificados',
            to: 'creditverify',
            icon: mdiCheckCircle,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },
          {
            title: 'Confirmados',
            to: 'creditconfirm',
            icon: mdiHandCoin,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },
          {
            title: 'Finalizados',
            to: 'finished',
            icon: mdiCheckboxMultipleMarkedCircleOutline,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },
          {
            title: 'Rechazados',
            to: 'rejected',
            icon: mdiCloseCircleMultipleOutline,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },

        ],
      },
      {
        title: 'Clientes',
        to: '',
        icon: mdiAccountDetails,
        rol: ['Gerente', 'Administrador', 'Cobrador', 'Encargado'],
        account: [],
        childs: [
          {
            title: 'Listado',
            to: 'abmclient',
            icon: mdiViewList,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          },
          {
            title: 'Imagen/Ubicación',
            to: 'listciientlocationimg',
            icon: mdiImageMarker,
            rol: ['Gerente', 'Administrador', 'Encargado', 'Cobrador'],
            account: [],
          }
        ],
      },
      {
        title: 'Reportes',
        to: '',
        icon: mdiPrinter,
        rol: ['Gerente', 'Administrador', 'Encargado'],
        account: [],
        childs: [
          {
            title: 'Caja Diaria',
            to: 'reportcashday',
            icon: mdiClipboardTextClockOutline,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Caja Mensual',
            to: 'reportcashmonth',
            icon: mdiCalendarMonth,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Cobranzas',
            to: 'collectionbetweendate',
            icon: mdiMapMarkerPath,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Recibos',
            to: 'abmdocument',
            icon: mdiTextBoxCheck,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
          {
            title: 'Liquidaciones',
            to: 'abmsalary',
            icon: mdiFileSign,
            rol: ['Gerente', 'Administrador', 'Encargado'],
            account: [],
          },
        ],
      },
    ],
    rolUserLogged: null,
    nameUserLogged: null,
  }),
  mounted() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
    this.nameUserLogged = this.$store.getters.usuario.user_name
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
