import generar from './generar'
import caja from './caja'
import ventaReserva from './ventaReserva'
import ventasBar from './ventasBar'
// import generarExcelInformes from './generar_excel'

function generarReporte (datos, opciones) {
  generar.reporte(datos, opciones)
}
function generarCaja (datos, opciones) {
  caja.reporte(datos, opciones)
}
function generarVentaReserva (datos, opciones) {
  ventaReserva.reporte(datos, opciones)
}
function generarVentasBar (datos, opciones) {
  ventasBar.reporte(datos, opciones)
}
// function generarExcel (datos, opciones) {
//   generarExcelInformes.generar(datos, opciones)
// }
export default {
  generarReporte,
  generarVentaReserva,
  generarCaja,
  generarVentasBar,
  // generarExcel,
}
