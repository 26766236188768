<template>
  <div name="snackbars">
    <v-snackbar
      v-model="show"
      :color="color"
      :timeout="timeout"
      :top="'top'"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      color: '',
      text: '',
      timeout: 0,
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {

      if (mutation.type === 'SHOW_MESSAGE') {
        this.text = this.$store.getters.snack.text
        this.color = this.$store.getters.snack.color
        this.timeout = this.$store.getters.snack.timeout
        this.show = true
      }
    })
  },
}
</script>
