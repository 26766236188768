import moment from 'moment'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import store from '@/store'

Vue.use(Vuex)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import('@/views/cash/ABMCash.vue'),
  },
  {
    path: '/datatableCash',
    name: 'datatableCash',
    component: () => import('@/views/cash/datatables/DataTableCash.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/abmcash',
    name: 'abmcash',
    component: () => import('@/views/cash/ABMCash.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/employee',
    name: 'employee',
    component: () => import('@/views/employee/ABMEmployee.vue'),
  },
  {
    path: '/registeremployee/:id',
    name: 'registeremployee',
    props: true,
    component: () => import('@/views/employee/RegisterEmployee.vue'),
  },
  {
    path: '/assignclients/:id',
    name: 'assignclients',
    props: true,
    component: () => import('@/views/employee/assignClients.vue'),
  },
  {
    path: '/clientsassigned/:id',
    name: 'clientsassigned',
    props: true,
    component: () => import('@/views/employee/AssignedClient.vue'),
  },

  {
    path: '/salarysettlement',
    name: 'salarysettlement',
    props: true,
    component: () => import('@/views/employee/salarySettlement.vue'),
  },
  {
    path: '/creditcollections/:id',
    name: 'creditcollections',
    props: true,
    component: () => import('@/views/credit/creditCollections.vue'),
  },
  {
    path: '/collectionbetweendate',
    name: 'collectionbetweendate',
    props: true,
    component: () => import('@/views/credit/collectionBetweenDate.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    props: true,
    component: () => import('@/views/calendar/calendar.vue'),
  },
  {
    path: '/reportcollection',
    name: 'reportcollection',
    component: () => import('@/views/credit/reportCollection.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/checkinout',
    name: 'checkinout',
    props: true,
    component: () => import('@/views/checkinout/CheckInOutList.vue'),
  },
  {
    path: '/salary',
    name: 'salary',
    props: true,
    component: () => import('@/views/employee/ABMSalary.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/ABMUser.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/DataTableUser',
    name: 'datatableUser',
    component: () => import('@/views/user/DataTableUser.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/fichada',
    name: 'fichada',
    component: () => import('@/views/checkinout/CheckIn.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/datatableClient',
    name: 'datatableClient',
    component: () => import('@/views/client/datatables/DataTableClient.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/abmclient',
    name: 'abmclient',
    component: () => import('@/views/client/ABMClient.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/listciientlocationimg',
    name: 'listciientlocationimg',
    component: () => import('@/views/client/ClientLocationImage.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/abmdocument',
    name: 'abmdocument',
    component: () => import('@/views/documentPayment/ABMDocumentPayment.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/abmsalary',
    name: 'abmsalary',
    component: () => import('@/views/salaryList/ABMSalary.vue'),
    meta: {
      layout: 'blank',
    },
  },

 
  {
    path: '/registerclient/:id',
    name: 'registerclient',
    component: () => import('@/views/client/RegisterClient.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/credit',
    name: 'credit',
    component: () => import('@/views/credit/ABMCredit.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // rejected
  {
    path: '/creditConfirm',
    name: 'creditconfirm',
    component: () => import('@/views/credit/CreditConfirm.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/rejected',
    name: 'rejected',
    component: () => import('@/views/credit/CreditRejected.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/finished',
    name: 'finished',
    component: () => import('@/views/credit/CreditFinished.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/creditexpect',
    name: 'creditexpect',
    component: () => import('@/views/credit/CreditExpect.vue'),
    meta: {
      layout: 'blank',
    },

  },
  {
    path: '/collectionsToBill',
    name: 'collectionsToBill',
    component: () => import('@/views/credit/CollectionsToBill.vue'),
    meta: {
      layout: 'blank',
    },

  },
  {
    path: '/creditverify',
    name: 'creditverify',
    component: () => import('@/views/credit/CreditVerify.vue'),
    meta: {
      layout: 'blank',
    },

  },
  {
    path: '/registercredit/:id',
    name: 'registercredit',
    component: () => import('@/views/credit/RegisterCredit.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/client/report.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/recibomanual',
    name: 'recibomanual',
    component: () => import('@/views/report/reportPaymentDocumentManual.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportsalary',
    name: 'reportsalary',
    component: () => import('@/views/report/reportSalary.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportpaymentdocument',
    name: 'reportpaymentdocument',
    component: () => import('@/views/report/reportPaymentDocument.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportcredential',
    name: 'reportcredential',
    component: () => import('@/views/report/credential.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/credentialdialog',
    name: 'credentialdialog',
    component: () => import('@/views/credential/credentialdialog.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportcash',
    name: 'reportcash',
    component: () => import('@/views/cash/reportCash.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportcashday',
    name: 'reportcashday',
    component: () => import('@/views/cash/reportCashDay.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportcashmonth',
    name: 'reportcashmonth',
    component: () => import('@/views/cash/reportCashMonth.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/client/Payments.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/ejemplo',
    name: 'example',
    component: () => import('@/views/report/Ejemplojspdf.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reportcash',
    name: 'reportcash',
    component: () => import('@/views/cashRegister/report.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.beforeEach(async (to, from) => {
//   console.log(store.getters.usuario)
//   // if (!(Object.entries(store.getters.usuario).length === 0)) {
//   //   if (store.getters.usuario.token.expiration
//   //     && moment(store.getters.usuario.token.expiration).diff(moment().format(), 'second') <= 0) {
//   //     // this.cerrarSesion()
//   //     console.log('Fin de sesion')
//   //   }
//   // }
//     console.log(`Ir a: ${to.name}`)
//     console.log(from)
// })

// router.beforeEach(async (to, from) => {
//   console.log(to)
//   console.log(from)

//   return '/login'
// })

router.beforeEach((to, from, next) => {
  if (!(Object.entries(store.getters.usuario).length === 0)) {
    if (store.getters.usuario.token.expiration
      && moment(store.getters.usuario.token.expiration).diff(moment().format(), 'second') <= 0) {
      store.dispatch('agregarUsuarioAction', {})
      store.dispatch('showSnack', {
        text: 'Sesion Expirada',
        color: 'error',
        timeout: 2500,
      })
      next({ name: 'pages-login' })
    } else next()
  } else next()
})

export default router
